import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./clan3.css";

import avatar from "../../Images/coin.png";
import snow from "../../Images/snow.png"

const Clan3 = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className="C3Super">
      <div className="C3Container">
        <div className="C3Title">
          <div
            data-aos="fade-right"
            data-aos-easing="ease-in-out"
            data-aos-delay="100"
          >
            <h2>THE PLAN</h2>
          </div>
        </div>
        <div className="C3SubContainer">
          <div
            className="C3Image"
            data-aos="fade"
            data-aos-easing="ease-in-out"
            data-aos-delay="100"
          >
            <img src={snow} alt="" />
          </div>
          <div
            className="C3Text"
            data-aos="fade"
            data-aos-offset="200"
            data-aos-duration="2000"
            data-aos-easing="ease-in-out"
          >
            <p>
              Team will push with callers, trending and buyback to be the top 1 Solana meme token
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clan3;
